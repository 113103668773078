import * as Sentry from '@sentry/browser';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';
import {
  CaptureContext,
  Event,
  EventHint,
  Scope,
  Severity,
} from '@sentry/types';
import { isApolloError } from 'apollo-client';
import { GraphQLError, GraphQLFormattedError } from 'graphql';
import every from 'lodash/every';
import isString from 'lodash/isString';

import { ErrorCode } from '../../../legacy/app-lib/common/typings';

import config from '../config';

/**
 * Since in isomorphic app same error can happen in back and client side and to
 * avoid having duplicates in sentry we are grouping error by their exception
 * message.
 */
export function beforeSend(event: Event, hint?: EventHint): Event {
  if (!hint || !hint.originalException) return event;

  const exception = hint.originalException;
  event.fingerprint = [isString(exception) ? exception : exception.message];
  return event;
}

export function init(): void {
  Sentry.init({
    ...config.sentry,
    beforeSend,
    integrations: [new DedupeIntegration()],
    ignoreErrors: ['Response not successful', 'FetchError'],
  });
  Sentry.setTag('context', config.isBrowser ? 'browser' : 'server');
}

export interface FormattedError extends GraphQLFormattedError {
  code?: ErrorCode;
  context?: any; // tslint:disable-line:no-any
}

function areAllErrorsExpected(
  graphQLErrors: ReadonlyArray<GraphQLError>,
): boolean {
  return every(graphQLErrors, (graphQLError: GraphQLError) => {
    const errorCode: ErrorCode | undefined = (graphQLError as FormattedError)
      ?.code;
    if (errorCode && Object.values(ErrorCode).includes(errorCode)) {
      return true;
    }
  });
}

// tslint:disable-next-line:no-any
export function captureException(error: Error, extras: any = {}): void {
  if (isApolloError(error)) {
    const { graphQLErrors } = error;

    if (areAllErrorsExpected(graphQLErrors)) return;
  }

  Sentry.withScope((scope: Scope) => {
    const errorExtras = { ...extras };
    let originalError = error;
    if (!(error instanceof Error)) {
      errorExtras.nonError = error;
      originalError = new Error('Malformed error');
    }
    scope.setExtras(errorExtras);
    Sentry.captureException(originalError);
  });
}

export function captureMessage(
  message: string,
  captureContext?: CaptureContext | Severity,
  // tslint:disable-next-line: no-any
  extras: any = {},
): void {
  Sentry.withScope((scope: Scope) => {
    const errorExtras = { ...extras };
    scope.setExtras(errorExtras);
    Sentry.captureMessage(message, captureContext);
  });
}

import React, { FC, ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { withTheme } from 'styled-components';

import { Color } from '../../theme';
import { ThemedSymbolProps } from './typings';

const EyeClose: FC<ThemedSymbolProps> = ({
  theme,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  active,
  ...svgProps
}: ThemedSymbolProps): ReactElement => (
  <Svg {...svgProps}>
    <G fill="none" fillRule="evenodd">
      <Path d="M0-1h16v16H0z" />
      <Path
        fill={theme.color[Color.GRAY_300]}
        d="M2.467 4.295l2.087 2.088a3.5 3.5 0 0 0 4.063 4.063l1.266 1.266A6.597 6.597 0 0 1 7.99 12C5.598 12 3.027 10.564.278 7.691a1 1 0 0 1 0-1.382 21.568 21.568 0 0 1 2.189-2.014zm3.645-2.011A6.578 6.578 0 0 1 7.99 2c2.392 0 4.969 1.436 7.73 4.307a1 1 0 0 1 0 1.386 21.716 21.716 0 0 1-2.19 2.008l-2.084-2.084a3.5 3.5 0 0 0-4.063-4.063l-1.27-1.27zM1.636.636a1 1 0 0 1 1.414 0L14.364 11.95a1 1 0 1 1-1.414 1.414L1.636 2.05a1 1 0 0 1 0-1.414z"
      />
    </G>
  </Svg>
);

export default withTheme(EyeClose);

import React, { ReactElement } from 'react';
import { G, Svg, Path } from 'react-native-svg';
import { ThemedSymbolProps } from './typings';
import { Color } from '../../theme';

const WarningBubble = ({
  theme,
  active,
  color,
  ...svgProps
}: ThemedSymbolProps): ReactElement => (
  <Svg {...svgProps}>
    <G fill={theme.color[active ? color || Color.PRIMARY : Color.GRAY_300]}>
      <Path d="M11.4262 5.19238H13.8878C14.0416 5.19238 14.1955 5.34623 14.1955 5.50008V6.42315C14.1955 8.23084 14.0801 10.0385 13.8108 11.8078L13.657 12.5385C13.5801 12.9616 13.3493 13.3078 12.9647 13.5001C12.8493 13.5385 12.7339 13.577 12.6185 13.577C12.5032 13.577 12.3878 13.5385 12.2724 13.5001C11.8878 13.3078 11.657 12.9616 11.5801 12.5385L11.4647 11.8078C11.1955 10.0385 11.0801 8.23084 11.0801 6.42315V5.50008C11.1185 5.30777 11.2724 5.19238 11.4262 5.19238Z" />
      <Path d="M13.8116 15.6538C13.8116 16.291 13.2949 16.8077 12.6578 16.8077C12.0206 16.8077 11.5039 16.291 11.5039 15.6538C11.5039 15.0167 12.0206 14.5 12.6578 14.5C13.2949 14.5 13.8116 15.0167 13.8116 15.6538Z" />
      <Path d="M12.619 22C10.3883 22 8.2344 21.3462 6.38825 20.0769C6.0421 20.1538 5.7344 20.2308 5.42671 20.2692C4.84979 20.3846 4.2344 20.4231 3.69594 20.3846C3.11902 20.3846 2.5421 20.2692 2.00363 20.1538C1.69594 20.0769 1.38825 19.9615 1.08056 19.8462C1.00363 19.8077 0.88825 19.7692 0.811327 19.7308C0.772865 19.6923 0.734404 19.6923 0.695942 19.6538C0.234404 19.4615 -0.0348271 19.0769 0.00363445 18.6154C0.00363445 18.1923 0.272865 17.8077 0.695942 17.6538C1.00363 17.5385 1.27287 17.3846 1.5421 17.1923C1.81133 17.0385 2.0421 16.8077 2.2344 16.5769C2.42671 16.3462 2.61902 16.1154 2.77287 15.8462C2.00363 14.3462 1.61902 12.6923 1.61902 11C1.61902 4.96132 6.50342 0.0382418 12.5421 0H12.619C15.5421 0 18.3113 1.11538 20.3883 3.19231C22.4652 5.26923 23.619 8.03846 23.619 10.9998C23.619 17.0767 18.6959 21.9998 12.619 21.9998V22ZM6.81155 17.8462L7.23462 18.1538C8.81155 19.3462 10.6577 19.9615 12.6579 19.9615C17.581 19.9615 21.581 15.9231 21.581 10.9998C21.581 8.5767 20.6579 6.34571 18.9272 4.65341C17.2348 2.9611 14.9656 1.99956 12.5808 2.03802C7.6577 2.07648 3.6577 6.07648 3.6577 10.9998C3.6577 12.5382 4.04232 14.0382 4.77308 15.3459L5.00385 15.8075L4.81155 16.269C4.50385 16.9229 4.1577 17.4998 3.73462 17.9613C3.61924 18.1152 3.46539 18.2305 3.35001 18.3459C3.46539 18.3459 3.61924 18.3844 3.77308 18.3844C4.19616 18.3844 4.6577 18.3459 5.11924 18.269C5.50385 18.1921 5.88847 18.1152 6.31155 17.9613L6.81155 17.8462Z" />
    </G>
  </Svg>
);

export default WarningBubble;

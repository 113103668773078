import { rgbAlpha } from './helper';
import palette from './palette';
import { Color, ColorDictionary } from './typings';
import * as newColors from '../../theming/colors';

export * from './typings';
export * from './palette';

const DEFAULT_LIGHT_OPACITY = 0.15;

const dangerColor = palette.accent.red1;
const successColor = palette.accent.green4;
const redMeatColor = palette.accent.red2;
const poultryColor = palette.accent.yellow1;
const seaShellColor = palette.accent.blue;
const rabbitColor = palette.accent.brown;

const colors: ColorDictionary = {
  [Color.PRIMARY]: newColors.blueStone,
  [Color.SECONDARY]: newColors.sanMarino,
  [Color.TERTIARY]: newColors.minsk,
  [Color.QUATERNARY]: newColors.persimmon,
  [Color.DANGER]: dangerColor,
  [Color.DANGER_ALT]: rgbAlpha(newColors.persimmon, DEFAULT_LIGHT_OPACITY),
  [Color.SUCCESS]: successColor,
  [Color.SUCCESS_ALT]: rgbAlpha(newColors.gulfStream, DEFAULT_LIGHT_OPACITY),
  [Color.SUNNY]: newColors.sunglow,
  [Color.LIGHT]: palette.gray.white,
  [Color.PRIMARY_ALT]: newColors.gulfStream,
  [Color.SECONDARY_ALT]: newColors.blueBell,
  [Color.TERTIARY_ALT]: newColors.eastSide,
  [Color.QUATERNARY_ALT]: newColors.waxFlower,
  [Color.SUNNY_ALT]: newColors.kournikova,
  [Color.DARK_ALT]: newColors.abbey,
  [Color.GRAY_100]: palette.gray.gray100,
  [Color.GRAY_200]: palette.gray.gray200,
  [Color.GRAY_300]: palette.gray.gray300,
  [Color.GRAY_400]: palette.gray.gray400,
  [Color.GRAY_500]: newColors.jumbo,
  [Color.GRAY]: palette.gray.gray300,
  [Color.DARKER_GRAY]: palette.gray.gray500,
  [Color.DARK]: newColors.shark,
  [Color.DISABLED]: palette.gray.gray500,
  [Color.POULTRY]: poultryColor,
  [Color.GLUTEN_FREE]: newColors.sunglow,
  [Color.RED_MEAT]: redMeatColor,
  [Color.SEA_SHELL]: seaShellColor,
  [Color.LACTOSE_FREE]: newColors.sanMarino,
  [Color.PORK]: newColors.minsk,
  [Color.RABBIT]: rabbitColor,
  [Color.VEGGIE]: newColors.blueStone,
  [Color.SPICY]: newColors.persimmon,
  [Color.ALCOHOL]: newColors.persimmon,
  [Color.TWITTER]: '#1DA1F2',
  [Color.INSTAGRAM]: '#c13584',
  [Color.FACEBOOK]: '#3b5998',
  [Color.TABLE_ROW]: '#F2F2F8',
  [Color.PRIMARY_LIGHT]: newColors.jaggedIce,
  [Color.SECONDARY_LIGHT]: newColors.whiteRock,
  [Color.TERTIARY_LIGHT]: newColors.whitePointer,
  [Color.QUATERNARY_LIGHT]: newColors.peach,
  [Color.SUNNY_LIGHT]: newColors.eggWhite,
  [Color.DANGER_LIGHT]: rgbAlpha(dangerColor, DEFAULT_LIGHT_OPACITY),
  [Color.SUCCESS_LIGHT]: rgbAlpha(successColor, DEFAULT_LIGHT_OPACITY),
  [Color.DARK_LIGHT]: newColors.mecury,
  [Color.DISABLED_LIGHT]: palette.gray.gray300,
  [Color.POULTRY_LIGHT]: rgbAlpha(poultryColor, DEFAULT_LIGHT_OPACITY),
  [Color.GLUTEN_FREE_LIGHT]: newColors.eggWhite,
  [Color.RED_MEAT_LIGHT]: rgbAlpha(redMeatColor, DEFAULT_LIGHT_OPACITY),
  [Color.SEA_SHELL_LIGHT]: rgbAlpha(seaShellColor, DEFAULT_LIGHT_OPACITY),
  [Color.LACTOSE_FREE_LIGHT]: newColors.whiteRock,
  [Color.PORK_LIGHT]: newColors.whitePointer,
  [Color.RABBIT_LIGHT]: rgbAlpha(rabbitColor, DEFAULT_LIGHT_OPACITY),
  [Color.VEGGIE_LIGHT]: newColors.jaggedIce,
  [Color.SPICY_LIGHT]: newColors.peach,
  [Color.ALCOHOL_LIGHT]: newColors.peach,
  [Color.EXPIRATION_SALE_BACKGROUND]: '#FFDA62',
};

export default colors;

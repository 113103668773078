import omit from 'lodash/omit';
import React, { FC, ReactElement } from 'react';
import { withTheme } from 'styled-components';

import { Color, Theme } from '../theme';
import symbolCollections from './collections';
import { SymbolKey, SymbolProps } from './collections/typings';
import { scaleDimension } from './helper';
import { Wrapper } from './styledComponents';

export interface Props {
  theme: Theme;
  symbolKey: SymbolKey;
  active?: boolean;
  scale?: number;
  width?: number | string;
  height?: number | string;
  color?: Color;
  tabIndex?: string;
  wrapperColor?: Color;
}

const Symbol: FC<Props> = ({
  symbolKey,
  theme,
  color,
  active = true,
  scale = 1,
  wrapperColor,
  ...svgProps
}: Props): ReactElement => {
  const {
    component: SymbolComponent,
    baseWidth,
    baseHeight,
    scaleWithHeight,
  } = symbolCollections[symbolKey];
  const scaledSVGProps: SymbolProps = scaleDimension(
    baseWidth,
    baseHeight,
    scale,
    scaleWithHeight,
  );

  const symbolComponent = (
    <SymbolComponent
      theme={theme}
      active={active}
      color={color}
      {...scaledSVGProps}
      {
        /** svgs that alias react-native-svg inject style on props causing issues in chrome, so omitting that property */
        ...omit(svgProps, 'style')
      }
    />
  );

  return wrapperColor ? (
    <Wrapper theme={theme} wrapperColor={wrapperColor}>
      {symbolComponent}
    </Wrapper>
  ) : (
    symbolComponent
  );
};

export * from './collections/typings';
export { default as symbolDescription } from './collections/symbolDescription';
export default withTheme(Symbol);

import { IncomingMessage } from 'http';

import { GraphQLFormattedError } from 'graphql';
import { NEXT_DATA } from 'next/dist/next-server/lib/utils';

import { ErrorCode } from '@app-lib/common/typings';

export interface CustomReq extends IncomingMessage {
  locale: string;
  nonce: string;
  messages: {};
}

declare global {
  interface Window {
    __NEXT_DATA__: NEXT_DATA;
    ReactIntlLocaleData: { [language: string]: Locale };

    /** Browser specifics */
    safari: any; // tslint:disable-line:no-any
    opera: any; // tslint:disable-line:no-any
    InstallTrigger: any; // tslint:disable-line:no-any
    opr: any; // tslint:disable-line:no-any
    chrome: any; // tslint:disable-line:no-any
    StyleMedia: any; // tslint:disable-line:no-any
    isIE: any; // tslint:disable-line:no-any
  }
}

export interface FormattedError extends GraphQLFormattedError {
  code?: ErrorCode;
  context?: any; // tslint:disable-line:no-any
}

export enum Routes {
  NOT_FOUND = '/404',
  FRIDGE = '/fridge',
  DELIVERY = '/delivery',
  HOME = '/',
  PRODUCT_DETAILS = '/p/[id]',
  FOOD_COUNTER = '/food-counter',
  SIGNIN = '/signin',
  TRANSACTIONS = '/transactions',
  SIGNUP = '/signup',
  SIGNIN_OR_SIGNUP = '/signin-or-signup',
  SIGNUP_CONFIRMATION = '/signup/confirmation',
  SIGNUP_AMOUNT_SELECTION = '/signup/amountSelection',
  SIGNUP_BILLING_ENTITY = '/signup/billingEntity',
  SIGNUP_BADGE_PAIRING = '/signup/badgePairing',
  SIGNUP_DELIVERY_SITE = '/signup/deliverySite',
  SIGNUP_PAYMENT_INFO = '/signup/paymentInfo',
  SIGNUP_WELCOME = '/signup/welcome',
  SIGNUP_COMPLETED = '/signup/completed',
  SIGNUP_REGISTRATION_CODE = '/signup/registrationCode',
  MOBILE_APP_REDIRECT = '/mobile-app-redirect',
  REQUEST_PASSWORD_RESET = '/request-password-reset',
  RESET_PASSWORD = '/reset-password',
  ADMIN = '/admin',
  ADMIN_ACCOUNT_MANAGEMENT = '/admin/accounts',
  ADMIN_ACCOUNT_REPORTING = '/admin/reporting',
  ADMIN_ACCOUNT_CREATION = '/admin/accounts/create',
  ADMIN_ACCOUNT_EDITION = '/admin/accounts/[id]',
  ADMIN_TRANSACTION_EXPORT = '/admin/transaction-export/',
}

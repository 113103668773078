import { TextStyle, textStyles as commonTextStyles } from './common';

export * from './common';

const body1 = {
  fontSize: '1em',
  lineHeight: '1.38',
  letterSpacing: '-0.3px',
};

const body2 = {
  fontSize: '0.875em',
  letterSpacing: '-0.3px',
};

const body3 = {
  fontSize: '0.75em',
  lineHeight: '1.33',
  fontWeight: '500',
};

export default {
  ...commonTextStyles,
  [TextStyle.TINY_BUTTON_LABEL]: {
    ...commonTextStyles[TextStyle.BUTTON_LABEL],
    fontSize: '0.75em',
    fontWeight: '600',
    letterSpacing: '-0.2px',
    lineHeight: '1',
  },
  [TextStyle.BUTTON_LABEL]: {
    ...commonTextStyles[TextStyle.BUTTON_LABEL],
    fontSize: '0.875em',
    fontWeight: '600',
    letterSpacing: '-0.3px',
    lineHeight: '1',
  },
  [TextStyle.HEADER_1]: {
    ...commonTextStyles[TextStyle.HEADER_1],
    fontSize: '3em',
    fontWeight: '600',
  },
  [TextStyle.HEADER_2]: {
    ...commonTextStyles[TextStyle.HEADER_2],
    fontSize: '2em',
    fontWeight: '600',
    lineHeight: '1.25',
  },
  [TextStyle.HEADER_3]: {
    ...commonTextStyles[TextStyle.HEADER_3],
    fontSize: '1.5em',
    fontWeight: '700',
    lineHeight: '1.33',
  },
  [TextStyle.BODY_1]: {
    ...commonTextStyles[TextStyle.BODY_1],
    ...body1,
  },
  [TextStyle.BODY_2]: {
    ...commonTextStyles[TextStyle.BODY_2],
    ...body2,
  },
  [TextStyle.BODY_3]: {
    ...commonTextStyles[TextStyle.BODY_3],
    ...body3,
  },
};

import { DataProxy } from 'apollo-cache/src/types/DataProxy';
import {
  defaultDataIdFromObject,
  IdGetterObj,
  InMemoryCache,
  InMemoryCacheConfig,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import get from 'lodash/get';
import { ErrorCode, Nillable, Nullable } from '../typings';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [],
    },
  },
});

let cache: InMemoryCache;

export function createCache(
  initialState: {} = {},
  config?: InMemoryCacheConfig,
): InMemoryCache {
  cache = new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject(object: IdGetterObj): Nillable<string> {
      // eslint-disable-next-line no-underscore-dangle
      if (object.__typename === 'CartProduct') {
        const priceAmount = get(object, 'price').amount;
        return `CartProduct:${object.id}-${priceAmount}`;
      }
      // eslint-disable-next-line no-underscore-dangle
      if (object.__typename === 'TransactionItem') {
        const price = get(object, 'saved_price');
        return `TransactionItem:${object.id}-${price}`;
      }
      return defaultDataIdFromObject(object);
    },
    ...config,
  });
  cache.restore(initialState);
  return cache;
}

export function getCache(): InMemoryCache {
  return cache;
}

// @see https://github.com/apollographql/react-apollo/issues/2175
export function readQuerySafely<QueryType, TVariables = {}>(
  options: DataProxy.Query<TVariables>,
  optimistic = false,
): Nullable<QueryType> {
  try {
    return cache.readQuery(options, optimistic);
  } catch (err) {
    return null;
  }
}

export const timeoutRegExp = new RegExp(ErrorCode.NETWORK_TIMEOUT);

export function checkIsNetworkTimeoutError(error?: Error): boolean {
  if (error) return timeoutRegExp.test(error.message);
  return false;
}

const networkErrorMessages = [
  ErrorCode.NETWORK_TIMEOUT,
  ErrorCode.NETWORK_REQUEST_ERROR,
  ErrorCode.NETWORK_REQUEST_ERROR_WEB,
];

export const networkErrorsRegExp = new RegExp(networkErrorMessages.join('|'));

export function checkIsNetworkError(error?: Error): boolean {
  if (error) return networkErrorsRegExp.test(error.message);
  return false;
}

import mapValues from 'lodash/mapValues';
import { FC } from 'react';

import { makePathBasedSymbol } from '../helper';
import EyeClose from './EyeClose';
import EyeOpen from './EyeOpen';
import LogoColorHorizontal from './LogoColorHorizontal';
import LogoWhiteHorizontal from './LogoWhiteHorizontal';
import symbolDescription, { SymbolDescription } from './symbolDescription';
import { ThemedSymbolProps } from './typings';
import PaymentCardVisa from './PaymentCardVisa';
import PaymentCardMasterCard from './PaymentCardMasterCard';
import PaymentCardAmericanExpress from './PaymentCardAmericanExpress';
import PaymentCardDinersClub from './PaymentCardDinersClub';
import PaymentCardDiscover from './PaymentCardDiscover';
import PaymentCardJCB from './PaymentCardJCB';
import PaymentCardUnionPay from './PaymentCardUnionPay';
import PaymentCardMaestro from './PaymentCardMaestro';
import PaymentCardDefault from './PaymentCardDefault';
import PaymentCardElo from './PaymentCardElo';
import PaymentCardHiperCard from './PaymentCardHiperCard';
import PiggyBank from './PiggyBank';
import Placeholder from './Placeholder';
import ChefHat from './ChefHat';
import PaymentMealVoucherCard from './PaymentMealVoucherCard';
import NutriscoreA from './NutriscoreA';
import NutriscoreB from './NutriscoreB';
import NutriscoreC from './NutriscoreC';
import NutriscoreD from './NutriscoreD';
import NutriscoreE from './NutriscoreE';
import PromoCode from './PromoCode';
import WarningBubble from './WarningBubble';
import CheckOutlined from './CheckOutlined';

export type SymbolMetadata = {
  baseWidth: number;
  baseHeight: number;
  component: FC<ThemedSymbolProps>;
  scaleWithHeight?: boolean;
};

export type SymbolMetadataDictionary = { [key: string]: SymbolMetadata };

const CREDIT_CARD_BASE_WIDTH = 750;
const CREDIT_CARD_BASE_HEIGHT = 471;

const NUTRISCORE_BASE_WIDTH = 1869;
const NUTRISCORE_BASE_HEIGHT = 1046;
const classSymbolByKey: SymbolMetadataDictionary = {
  placeholder: {
    baseHeight: 16,
    baseWidth: 16,
    component: Placeholder,
  },
  'logo/white-horizontal': {
    baseWidth: 152,
    baseHeight: 32,
    scaleWithHeight: true,
    component: LogoWhiteHorizontal,
  },
  'logo/color-horizontal': {
    baseWidth: 886,
    baseHeight: 188,
    scaleWithHeight: true,
    component: LogoColorHorizontal,
  },
  'accessibility/eye-open': {
    baseWidth: 16,
    baseHeight: 10,
    component: EyeOpen,
  },
  'accessibility/eye-close': {
    baseWidth: 16,
    baseHeight: 14,
    component: EyeClose,
  },
  'credit-card/visa': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardVisa,
  },
  'credit-card/mastercard': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardMasterCard,
  },
  'credit-card/american-express': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardAmericanExpress,
  },
  'credit-card/diners-club': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardDinersClub,
  },
  'credit-card/discover': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardDiscover,
  },
  'credit-card/jcb': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardJCB,
  },
  'credit-card/unionpay': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardUnionPay,
  },
  'credit-card/maestro': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardMaestro,
  },
  'credit-card/mir': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardDefault,
  },
  'credit-card/elo': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardElo,
  },
  'credit-card/hiper': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardDefault,
  },
  'credit-card/hipercard': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardHiperCard,
  },
  'credit-card/default': {
    baseWidth: CREDIT_CARD_BASE_WIDTH,
    baseHeight: CREDIT_CARD_BASE_HEIGHT,
    component: PaymentCardDefault,
  },
  'display/chef-hat': {
    baseWidth: 45,
    baseHeight: 48,
    component: ChefHat,
  },
  'nutriscore/a': {
    baseWidth: NUTRISCORE_BASE_WIDTH,
    baseHeight: NUTRISCORE_BASE_HEIGHT,
    scaleWithHeight: true,
    component: NutriscoreA,
  },
  'nutriscore/b': {
    baseWidth: NUTRISCORE_BASE_WIDTH,
    baseHeight: NUTRISCORE_BASE_HEIGHT,
    scaleWithHeight: true,
    component: NutriscoreB,
  },
  'nutriscore/c': {
    baseWidth: NUTRISCORE_BASE_WIDTH,
    baseHeight: NUTRISCORE_BASE_HEIGHT,
    scaleWithHeight: true,
    component: NutriscoreC,
  },
  'nutriscore/d': {
    baseWidth: NUTRISCORE_BASE_WIDTH,
    baseHeight: NUTRISCORE_BASE_HEIGHT,
    scaleWithHeight: true,
    component: NutriscoreD,
  },
  'nutriscore/e': {
    baseWidth: NUTRISCORE_BASE_WIDTH,
    baseHeight: NUTRISCORE_BASE_HEIGHT,
    scaleWithHeight: true,
    component: NutriscoreE,
  },

  'payment/piggy-bank': {
    baseWidth: 27,
    baseHeight: 24,
    component: PiggyBank,
  },
  'payment/meal-voucher-card': {
    baseWidth: 40,
    baseHeight: 28,
    component: PaymentMealVoucherCard,
  },
  'control/promo-code': {
    baseWidth: 11,
    baseHeight: 16,
    component: PromoCode,
  },
  'display/warning-bubble': {
    baseWidth: 24,
    baseHeight: 22,
    component: WarningBubble,
  },
  'display/check-outlined': {
    baseWidth: 128,
    baseHeight: 128,
    component: CheckOutlined,
  },
  ...mapValues(
    symbolDescription,
    ({
      activeColor,
      pathProps,
      ...metadata
    }: SymbolDescription): SymbolMetadata => ({
      ...metadata,
      component: makePathBasedSymbol(pathProps, activeColor),
    }),
  ),
};

export default classSymbolByKey;

import React, { ReactElement } from 'react';
import { Svg } from 'react-native-svg';

import { SymbolProps } from './typings';

const Placeholder = ({ ...svgProps }: SymbolProps): ReactElement => (
  <Svg {...svgProps} />
);

export default Placeholder;

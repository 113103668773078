import React, { FC, ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { withTheme } from 'styled-components';

import { Color } from '../../theme';
import { ThemedSymbolProps } from './typings';

const EyeOpen: FC<ThemedSymbolProps> = ({
  theme,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  active,
  ...svgProps
}: ThemedSymbolProps): ReactElement => (
  <Svg {...svgProps}>
    <G fill="none" fillRule="evenodd">
      <Path d="M0-3h16v16H0z" />
      <Path
        fill={theme.color[Color.GRAY]}
        d="M7.99 10C5.598 10 3.027 8.564.278 5.691a1 1 0 0 1 0-1.382C3.027 1.436 5.598 0 7.99 0c2.392 0 4.969 1.436 7.73 4.307a1 1 0 0 1 0 1.386C12.959 8.564 10.382 10 7.99 10zM8 8.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM7.995 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
      />
    </G>
  </Svg>
);

export default withTheme(EyeOpen);

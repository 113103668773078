import React, { FC, ReactElement } from 'react';
import Svg, { G, Path, PathProps } from 'react-native-svg';

import { scaleUnitLess } from '../theme';
import { Color } from '../theme/colors';
import { SymbolProps, ThemedSymbolProps } from './collections/typings';

const BASE_UNIT = 16;

export function scaleDimension(
  baseWidth: number,
  baseHeight: number,
  scale: number,
  scaleWithHeight?: boolean,
): SymbolProps {
  let scaleLength: number = baseHeight > baseWidth ? baseHeight : baseWidth;
  if (scaleWithHeight) scaleLength = baseHeight;

  const baseScale: number = 1 / (scaleLength / BASE_UNIT);
  const scaledWidth: number = baseWidth * baseScale * scale;
  const scaledHeight: number = baseHeight * baseScale * scale;

  return {
    width: scaleUnitLess(scaledWidth),
    height: scaleUnitLess(scaledHeight),
    viewBox: `0 0 ${baseWidth} ${baseHeight}`,
  };
}

export function makePathBasedSymbol(
  pathProps: PathProps | PathProps[],
  activeColor: Color,
): FC<ThemedSymbolProps> {
  return ({
    active,
    theme,
    color,
    ...svgProps
  }: ThemedSymbolProps): ReactElement => {
    const pathList = Array<PathProps>()
      .concat(pathProps)
      // Using index in key here is fine because the list will never be dynamically refreshed.
      // eslint-disable-next-line react/no-array-index-key
      .map((props, index) => <Path key={index} {...props} />);
    return (
      <Svg {...svgProps}>
        <G fill={theme.color[active ? color || activeColor : Color.GRAY_300]}>
          {pathList}
        </G>
      </Svg>
    );
  };
}

import { GraphQLFormattedError } from 'graphql';

export type NumericDictionary<T> = {
  [index: number]: T;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Dictionary<T = any> = {
  [index: string]: T;
};

export type Nullable<T> = T | null;

export type Optional<T> = T | undefined;

export type Nillable<T> = T | null | undefined;

export type OptionalKeys<T, K extends keyof T> = Pick<Partial<T>, K> &
  Omit<T, K>;

export interface TestProps {
  testID?: string;
}

export enum ErrorCode {
  API_TECHNICAL_ERROR = 'API_TECHNICAL_ERROR',
  LOCALE_ERROR = 'LOCALE_ERROR',
  AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  CART_EXPIRED = 'cart_expired',
  OUT_OF_STOCK = 'out_of_stock',
  PRODUCT_CONFLICT = 'conflict',
  INVALID_CART = 'invalid_cart',
  PAYMENT_REQUIRED = 'payment_required',
  BADGE_NOT_FOUND = 'badge_not_found',
  BADGE_ALREADY_PAIRED = 'badge_already_paired',
  PAYMENT_UNKNOWN_ERROR = 'payment_unknown_error',
  PAYMENT_TRANSACTION_ERROR = 'payment_transaction_error',
  CLIENT_ACCOUNT_ALREADY_CONFIRMED = 'client_account_already_confirmed',
  CLIENT_CONFLICT = 'client_conflict',
  UNCONFIRMED_EMAIL = 'unconfirmed_email',
  STORE_DELIVERY_UNAVAILABLE = 'store_delivery_unavailable',
  INVALID_SIGNUP_INPUT = 'invalid_signup_input',
  INVALID_CHANGE_PASSWORD = 'invalid_change_password_input',
  INVALID_PUBLIC_EMAIL = 'public_email_invalid',
  UNRECOGNIZED_EMAIL = 'unrecognized_email',
  REGISTRATION_CODE_INVALID = 'registration_code_invalid',
  NETWORK_TIMEOUT = 'Timeout exceeded',
  NETWORK_REQUEST_ERROR = 'Network request failed',
  NETWORK_REQUEST_ERROR_WEB = 'Failed to fetch',
}

export interface FormattedError extends GraphQLFormattedError {
  code?: ErrorCode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: any;
}

export interface FormattedNetworkError extends Error {
  result?: {
    code: ErrorCode;
    detail: string;
  };
}

export interface PaginatedQueryInput {
  offset?: number;
  limit?: number;
}

export interface Paginated<Item> {
  results: Item[];
  count: number;
  previous: Nullable<string>;
  next: Nullable<string>;
}

export interface PaginatedQueryVariables<Q extends PaginatedQueryInput> {
  query: Q;
}

export interface PaginatedQueryData<T> {
  paginatedData: Paginated<T>;
}

export interface SelectOption<T> {
  label: string;
  value: T;
}

import { Palette } from './typings';

export const palette: Palette = {
  accent: {
    green1: '#3FBAA9',
    green2: '#03DAC4',
    green3: '#007D68',
    green4: '#5cba3f',
    red1: '#FE7575',
    red2: '#B73943',
    yellow1: '#F7B300',
    yellow2: '#D7B11A',
    yellow3: '#F6C043',
    blue: '#6BA6C7',
    turquoise: '#7BB5B1',
    pink: '#FE7575',
    brown: '#C1997D',
  },
  gray: {
    white: '#FFFFFF',
    gray100: '#E7EAEA',
    gray200: '#F0F2F2',
    gray300: '#DDE1E0',
    gray400: '#BEBEBA',
    gray500: '#B3BEBC',
    dark: '#1A2826',
  },
};

export default palette;

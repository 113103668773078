export const white = '#fff';
export const black = '#000';

export const sanMarino = '#414cb3';
export const whiteRock = '#ede5d6';
export const blueBell = '#878dc5';

export const sunglow = '#fdc635';
export const eggWhite = '#fff4c1';
export const kournikova = '#ffd870';

export const minsk = '#532d83';
export const whitePointer = '#fbe3ff';
export const eastSide = '#a286c5';

export const persimmon = '#fe6f46';
export const peach = '#ffe1d9';
export const waxFlower = '#ffbba4';

export const blueStone = '#006463';
export const jaggedIce = '#c1e4e8';
export const gulfStream = '#75abaa';

export const shark = '#252526';
export const mecury = '#e2e2e2';
export const jumbo = '#78787c';
export const abbey = '#515155';

export default {
  white,
  black,
  sanMarino,
  whiteRock,
  blueBell,
  sunglow,
  eggWhite,
  kournikova,
  minsk,
  whitePointer,
  eastSide,
  persimmon,
  peach,
  waxFlower,
  blueStone,
  jaggedIce,
  gulfStream,
  shark,
  mecury,
  jumbo,
  abbey,
};

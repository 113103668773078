import { IntlShape, MessageDescriptor } from 'react-intl';
import { ApolloError } from 'apollo-client';
import { Dictionary, ErrorCode, FormattedError, Nullable } from '../typings';

export type ErrorProvider = (error: Error, extras: Dictionary) => void;

let errorProviderInstance: Nullable<ErrorProvider> = null;

export const unexpectedErrorMessageDescriptor: MessageDescriptor = {
  id: 'common.error.unexpected',
  defaultMessage:
    'An unexpected error occurred. Please try again later, if the problem persists send an email to {supportEmail}.',
};

export const networkErrorMessageDescriptor: MessageDescriptor = {
  id: 'common.error.networkError',
  defaultMessage:
    'It seems that your Internet connection has been lost. Please reconnect before trying again.',
};

export const supportEmailMessageDescriptor: MessageDescriptor = {
  id: 'common.email.support',
  defaultMessage: 'support@foodles.co',
};

export function setupErrorProvider(
  errorCatcher: Nullable<ErrorProvider>,
): void {
  errorProviderInstance = errorCatcher;
}
export function captureException(error: Error, extras: Dictionary = {}): void {
  if (!errorProviderInstance) throw new Error('Missing error catcher');
  errorProviderInstance(error, extras);
}

export function unexpectedErrorMessage(intl: IntlShape): string {
  return intl.formatMessage(unexpectedErrorMessageDescriptor, {
    supportEmail: intl.formatMessage(supportEmailMessageDescriptor),
  });
}

const DEFAULT_AUTHENTICATION_HANDLER = (): Promise<void> => Promise.resolve();
export const IGNORED_ERROR_CODES: ErrorCode[] = [
  ErrorCode.BAD_USER_INPUT,
  ErrorCode.CLIENT_ACCOUNT_ALREADY_CONFIRMED,
];

export interface HandleApolloErrorOptions {
  authenticationErrorHandler?: () => Promise<unknown>;
  ignoredErrorCodes?: ErrorCode[];
}

export function handleApolloError(
  { networkError, graphQLErrors }: ApolloError,
  {
    authenticationErrorHandler = DEFAULT_AUTHENTICATION_HANDLER,
    ignoredErrorCodes = IGNORED_ERROR_CODES,
  }: HandleApolloErrorOptions = {},
): void {
  if (networkError) captureException(networkError);

  if (graphQLErrors) {
    graphQLErrors.forEach((error: FormattedError): void => {
      const { locations, path = [], code, context } = error;
      if (code && ignoredErrorCodes.includes(code)) return;
      if (code === ErrorCode.AUTHENTICATION_ERROR) {
        authenticationErrorHandler().catch(captureException);
        return;
      }

      captureException(
        new Error(`GraphQL error - ${path.join('.')} - ${code}`),
        {
          locations,
          path,
          context,
          code,
        },
      );
    });
  }
}
